import React from "react";
import { BrowserRouter } from "react-router-dom";

import { AuthAppProvider } from "@homesusa/auth";
import { AppProvider, ThemeProvider } from "@homesusa/layout";
import { useYbug } from "@homesusa/core";
import AppRoutes from "./routes.component";
import { appState } from "../app-state";

export function App(): JSX.Element {
  const initialState = React.useMemo(() => appState, []);
  useYbug();

  return (
    <BrowserRouter>
      <ThemeProvider>
        <AuthAppProvider>
          <AppProvider initialState={initialState}>
            <AppRoutes />
          </AppProvider>
        </AuthAppProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}
