import React from "react";
import { LinearProgress } from "@mui/material";

import { useUserProfile } from "@homesusa/auth";
import { UserProvider } from "../providers";
import { UserPanel } from "../components";

function UserProfile(): JSX.Element {
  const userProfile = useUserProfile();
  if (!userProfile) {
    return <LinearProgress />;
  }
  return (
    <UserProvider userId={userProfile.id}>
      <UserPanel />
    </UserProvider>
  );
}

export default UserProfile;
