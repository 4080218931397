import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { LinearProgress } from "@mui/material";

import { useHasRole } from "@homesusa/auth";
import {
  PrivateRoute,
  AppContext,
  appPublicRoutesConfig,
  appPrivateRoutesConfig,
  EmployeePrivateRoute
} from "@homesusa/layout";
import { ErrorBoundary, RoleEmployee } from "@homesusa/core";
import UserGrid from "modules/users/pages/user-grid.component";
import UserDetail from "modules/users/pages/user-details.component";
import Home from "modules/users/pages/home.component";
import UserProfile from "modules/users/pages/user-profile.component";
import { appState } from "../app-state";

const EmailConfirmed = React.lazy(
  () => import("modules/users/pages/email-confirmed.component")
);

function AppRoutes(): JSX.Element {
  const { appStateDispatch } = React.useContext(AppContext);
  const { isMlsAdministrator, hasEmployeeRole } = useHasRole();

  React.useEffect(() => {
    appStateDispatch({
      type: "UpdateSidebarItem",
      payload:
        isMlsAdministrator || hasEmployeeRole([RoleEmployee.CompanyAdmin])
          ? appState.layout.sidebar.items
          : {}
    });
  }, [isMlsAdministrator, hasEmployeeRole]);

  return (
    <Suspense fallback={<LinearProgress />}>
      <Routes>
        {appPublicRoutesConfig.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route
          path="/"
          element={<PrivateRoute />}
          errorElement={<ErrorBoundary />}
        >
          {appPrivateRoutesConfig.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
          <Route
            element={
              <EmployeePrivateRoute
                allowedRoles={[RoleEmployee.CompanyAdmin]}
              />
            }
          >
            <Route path="users/*">
              <Route index element={<UserGrid />} />
              <Route path=":id" element={<UserDetail />} />
            </Route>
          </Route>
          <Route path="profile" element={<UserProfile />} />
          <Route index element={<Home />} />
        </Route>

        <Route path="/confirmed-email" element={<EmailConfirmed />} />
      </Routes>
    </Suspense>
  );
}

export default AppRoutes;
