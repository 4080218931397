import React from "react";
import { useParams } from "react-router-dom";

import { UserProvider } from "../providers";
import { UserPanel } from "../components";

function UserDetails(): JSX.Element {
  const params = useParams<{ id: string }>();
  if (params.id == null) {
    return <></>;
  }
  return (
    <UserProvider userId={params?.id}>
      <UserPanel />
    </UserProvider>
  );
}

export default UserDetails;
