import React from "react";
import { Navigate } from "react-router-dom";
import { LinearProgress } from "@mui/material";

import { useUserProfile } from "@homesusa/auth";

function Home(): JSX.Element {
  const userProfile = useUserProfile();

  if (userProfile?.id == null) {
    return <LinearProgress />;
  }

  return <Navigate to={`/profile`} />;
}

export default Home;
