import { People } from "@mui/icons-material";

import { appStateDefault, AppType, AppState } from "@homesusa/layout";

enum SidebarItemType {
  Listings = "users"
}

export const appState: AppState = {
  ...appStateDefault,
  currentApp: AppType.UserManagement,
  layout: {
    ...appStateDefault.layout,
    sidebar: {
      ...appStateDefault.layout.sidebar,
      selected: SidebarItemType.Listings,
      items: {
        [SidebarItemType.Listings]: {
          title: "Users",
          icon: People,
          items: [{ title: "Manage Users", endpoint: "/users" }]
        }
      }
    }
  }
};
