import React from "react";
import { Grid, Button, Box, Typography } from "@mui/material";
import { FormContext, Password, passwordReExp } from "@homesusa/form";
import { Save as SaveIcon } from "@mui/icons-material";
import { useResetPasswordByAdministrator } from "../hooks";
import { SectionBox } from "core/components";
import { UserPasswordContext } from "../contexts";

function SecurityFormAdmin(): JSX.Element {
  const { formState } = React.useContext<UserPasswordContext>(FormContext);
  const onSave = useResetPasswordByAdministrator();

  const enabled =
    formState.newPassword?.length > 0 &&
    formState.passwordConfirmation?.length > 0 &&
    formState.newPassword == formState.passwordConfirmation &&
    passwordReExp.test(formState.newPassword);

  return (
    <form>
      <Grid container>
        <Grid item xs={6}>
          <SectionBox title="Change Password">
            <Grid container>
              <Grid item xs={12}>
                <Typography component="div">
                  <b>Password Criteria</b>
                  <br />
                  Please enter a new password which must contain 8 to 20
                  characters and at least:
                  <ul>
                    <li>1 upper case letter</li>
                    <li>1 number</li>
                    <li>
                      1 of these special characters only: # $ @ ! . & * ? ^ %
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Password name="newPassword" />
              </Grid>
              <Grid item xs={6}>
                <Password name="passwordConfirmation" />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ height: "100%", display: "flex" }}>
                  <Box
                    sx={{
                      alignSelf: "end",
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-end"
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(): Promise<void> =>
                        onSave(formState.newPassword)
                      }
                      disabled={!enabled}
                      startIcon={<SaveIcon />}
                    >
                      Change Password
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </SectionBox>
        </Grid>
      </Grid>
    </form>
  );
}

export default SecurityFormAdmin;
